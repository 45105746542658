const CryptoJS = require('crypto-js')

const key = CryptoJS.enc.Utf8.parse('277bffde9c0edbbf')

const params = {
  mode: CryptoJS.mode.ECB,
  padding: CryptoJS.pad.Pkcs7
}

export function decrypt (encrypted) {
  return CryptoJS.AES.decrypt(encrypted, key, params).toString(CryptoJS.enc.Utf8)
}
